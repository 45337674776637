import topMenu from '../templates/components/topMenu/topMenu';

const runInitialElements = () => {
    const fadeLoader = () => setTimeout(() => document.getElementById('preloader').classList.add('closed'),500);

    fadeLoader();
}

export default function () {
    return (mod) => {
        window.addEventListener('load', () => {
            runInitialElements();
            mod();
        });
    }
};
