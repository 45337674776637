/**
 * @param  {} handler
 * @param  {} target
 * @param  {} isCollection=false
 */
export const toggleActiveOnClick = (handler, target, isCollection = false) => {
    let handlerElems = document.querySelectorAll(handler);
    let targetElem = document.querySelector(target);

    Array.from(handlerElems)
        .forEach(handler => handler.addEventListener('click', () => targetElem.classList.toggle('active'))
    );

}

export const getWindowWidth = () => {
    return window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
};

export const getWindowHeight = () => {
    return window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
};

export const scrollTop = () => {
    return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
};

