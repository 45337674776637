import { scrollTop } from '../../../js/helpers/helpers';
import './topMenu.scss';

const topMenu = () => {
    const onScroll = () => {
        const header = document.querySelector('#js-header');
        const loadTop = scrollTop();

        const modifyBars = (action = 'remove') =>
            [header].forEach(bar =>
                bar && (action === 'add'
                ? bar.classList.add('scrolled')
                : bar.classList.remove('scrolled'))
            );

        const changeHeader = top =>
            top > 100
                ? modifyBars('add')
                : modifyBars();

        changeHeader(loadTop);

        const onWindowChangeHandler = () => {
            const top = scrollTop();
            changeHeader(top);
        };

        window.addEventListener('scroll', onWindowChangeHandler);
    };

    const onMobileClick = () => {
        const toggler = document.querySelector('#js-burger');
        const menu = document.querySelector('#js-main-menu');

        const toggleMenu = () => {
          menu.classList.toggle('active');
          toggler.classList.toggle('active');
        };

        toggler.addEventListener('click', toggleMenu);
    }

    onScroll();
    onMobileClick();
};

export default topMenu();
